import { t } from 'i18next';

import { OpenLidError } from 'src/@types';
import { SUPPORT_PHONE_NUMBER } from 'src/constants';
import { LOC_NS, OPEN_LID_ERRORS_KEYS, ORDERS_KEYS } from 'src/i18n/types';

export const getErrorTypeFromErrorMessage = (errorMessage: string): OpenLidError => {
  if (errorMessage.includes('health check')) {
    return OpenLidError.BOT_UNHEALTHY;
  }

  if (errorMessage.includes('currently unavailable')) {
    return OpenLidError.BOT_UNAVAILABLE;
  }

  if (errorMessage.includes('manually grounded')) {
    return OpenLidError.BOT_GROUNDED;
  }

  if (errorMessage.includes('is offline')) {
    return OpenLidError.BOT_OFFLINE;
  }

  if (errorMessage.includes('on a trip')) {
    return OpenLidError.BOT_ON_TRIP;
  }

  if (errorMessage.includes('maintenance')) {
    return OpenLidError.BOT_NEEDS_MAINTENANCE;
  }

  if (errorMessage.includes('not in range')) {
    return OpenLidError.BOT_NOT_IN_RANGE;
  }

  if (errorMessage.includes('Robot is reserved')) {
    return OpenLidError.ROBOT_RESERVED_FOR_DIFFERENT_ORDER;
  }

  if (errorMessage.includes('Robot not found')) {
    return OpenLidError.MISSING_ROBOT_FOR_DELIVERY;
  }

  return OpenLidError.OTHER;
};

export const openLidErrorsT = (key: OPEN_LID_ERRORS_KEYS, fallback: string) => {
  return t(`${LOC_NS.OPEN_LID_ERRORS}:${key}`, fallback);
};

export const ordersT = (key: ORDERS_KEYS, fallback: string) => {
  return t(`${LOC_NS.ORDERS}:${key}`, fallback);
};

export const getErrorCopy = (errorType: OpenLidError) => {
  switch (errorType) {
    case OpenLidError.BOT_UNAVAILABLE:
      return openLidErrorsT(OPEN_LID_ERRORS_KEYS.UNAVAILABLE, 'This Coco is currently unavailable.');
    case OpenLidError.BOT_OFFLINE:
      return openLidErrorsT(OPEN_LID_ERRORS_KEYS.OFFLINE, 'This Coco is offline.');
    case OpenLidError.BOT_ON_TRIP:
      return openLidErrorsT(OPEN_LID_ERRORS_KEYS.ALREADY_ASSIGNED, 'This Coco is assigned to an order.');
    case OpenLidError.BOT_GROUNDED:
    case OpenLidError.BOT_NEEDS_MAINTENANCE:
    case OpenLidError.BOT_UNHEALTHY:
      return openLidErrorsT(OPEN_LID_ERRORS_KEYS.FAILED_HEALTH_CHECK, 'This Coco did not pass its health check.');
    case OpenLidError.BOT_NOT_IN_RANGE:
      return openLidErrorsT(OPEN_LID_ERRORS_KEYS.OUT_OF_RANGE, 'This Coco is not in range.');
    case OpenLidError.MISSING_ROBOT_FOR_DELIVERY:
      return `${openLidErrorsT(
        OPEN_LID_ERRORS_KEYS.MISSING_DESIGNATED_ROBOT,
        'This order is missing its designated robot.'
      )} ${ordersT(
        ORDERS_KEYS.CONTACT_COCO_SUPPORT_AT,
        'Please contact Coco Support at'
      )} ${SUPPORT_PHONE_NUMBER} ${ordersT(
        ORDERS_KEYS.TO_REQUEST_PROVIDER_CHANGE,
        'to request a change of delivery providers for this order.'
      )}`;
    case OpenLidError.ROBOT_RESERVED_FOR_DIFFERENT_ORDER:
      return openLidErrorsT(
        OPEN_LID_ERRORS_KEYS.RESERVED_FOR_DIFFERENT_ORDER,
        'This Coco is reserved for a different order. Please scan another Coco.'
      );
    case OpenLidError.OTHER:
    default:
      return openLidErrorsT(OPEN_LID_ERRORS_KEYS.FAILED_TO_OPEN, 'Lid failed to open.');
  }
};

export const hasSerialAttemptedToOpen = (x: unknown): x is { serialAttemptedToOpen: string } => {
  return typeof x === 'object' && x !== null && 'serialAttemptedToOpen' in x && !!x.serialAttemptedToOpen;
};
