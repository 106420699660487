import { styled } from '@stitches/react';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EVENTS, OpenLidError } from 'src/@types';
import WarningIcon from 'src/assets/warning.png';
import { SUPPORT_PHONE_NUMBER } from 'src/constants';
import { useAppContext } from 'src/context';
import { useAmplitude, useEta } from 'src/hooks';
import { LOC_NS, OPEN_LID_ERRORS_KEYS, ORDERS_KEYS } from 'src/i18n/types';
import { usePersistStore } from 'src/stores';
import { getErrorCopy, MAX_RETRY_COUNT } from 'src/utils';
import { Button } from '../Button';
import { CocoError } from '../CocoError';
import { LoadableRobotsList } from './LoadableRobotsList';

interface Props {
  openLidError: OpenLidError;
  hasDesignatedRobot: boolean;
  handleRetryClick: () => void;
  handleNewRobotClick: (robotSerial: string) => void;
}

const ErrorsContainer = styled('div', {
  display: 'flex',
  height: '80svh',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '500px',
  gap: '1rem',
});

const CenteredContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '90%',
});

const ErrorCard = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
  marginTop: '2rem',
  gap: '1rem',
  backgroundColor: '#f0f0f0',
  borderRadius: '8px',
});

const StyledImage = styled('img', {
  height: '36px',
});

const ErrorMessage = styled('p', {
  padding: '0.2rem',
  textAlign: 'left',
  fontSize: '1rem',
  fontWeight: '600',
  lineHeight: '1.5',
});

export const OrderOpenLidError = ({
  openLidError,
  hasDesignatedRobot,
  handleRetryClick,
  handleNewRobotClick,
}: Props) => {
  const { t } = useTranslation(LOC_NS.ORDERS);
  const { t: openLidErrorsT } = useTranslation(LOC_NS.OPEN_LID_ERRORS);
  const { logEvent } = useAmplitude();
  const { robotsAttemptedToOpen, openLidRetryMode: retryMode, openLidRetryCount: retryCount } = usePersistStore();
  const { robotId } = useAppContext();
  const router = useRouter();

  const waitForReturn = useMemo(() => {
    return [OpenLidError.BOT_NOT_IN_RANGE, OpenLidError.BOT_ON_TRIP].includes(openLidError) && hasDesignatedRobot;
  }, [hasDesignatedRobot, openLidError]);

  const { data: eta } = useEta({
    robotSerial: robotId,
    config: { enabled: waitForReturn, refetchInterval: 1000 * 15 },
  });

  const { errorMessage, shouldRetryScan } = useMemo(() => {
    let errorMessage = getErrorCopy(openLidError);
    let shouldRetryScan = false;

    if (waitForReturn) {
      errorMessage = !!eta
        ? openLidErrorsT(OPEN_LID_ERRORS_KEYS.SCAN_ON_RETURN_ETA, {
            robotSerial: robotId,
            eta: format(new Date(eta), 'p'),
          })
        : openLidErrorsT(OPEN_LID_ERRORS_KEYS.SCAN_ON_RETURN, {
            robotSerial: robotId,
          });
    }
    if (openLidError === OpenLidError.OTHER) {
      shouldRetryScan = true;
    }

    return { errorMessage, shouldRetryScan };
  }, [openLidError, waitForReturn, eta, openLidErrorsT, robotId]);

  const onClickRobot = useCallback(
    (robotSerial: string) => {
      handleNewRobotClick(robotSerial);
      logEvent({
        eventName: EVENTS.CLICK_OPEN_DIFFERENT_ROBOT,
        params: { robotSerial, robotsAlreadyAttempted: robotsAttemptedToOpen?.join(',') },
      });
    },
    [handleNewRobotClick, logEvent, robotsAttemptedToOpen]
  );

  const allowRetry: boolean = useMemo(() => {
    return shouldRetryScan && retryMode && retryCount < MAX_RETRY_COUNT;
  }, [shouldRetryScan, retryMode, retryCount]);

  const hasBlockingError: boolean = useMemo(() => {
    return openLidError === OpenLidError.MISSING_ROBOT_FOR_DELIVERY || waitForReturn;
  }, [openLidError, waitForReturn]);

  if (hasBlockingError) {
    return (
      <CenteredContainer>
        <CocoError errorMessage={errorMessage} />
        <Button onClick={() => router.push('/')}>{t(ORDERS_KEYS.RETURN_TO_SCAN, 'Return to Scan')}</Button>
      </CenteredContainer>
    );
  }

  return (
    <ErrorsContainer>
      <ErrorCard>
        <StyledImage src={WarningIcon.src} alt="coco-error" />
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ErrorCard>

      {allowRetry ? (
        <Button onClick={handleRetryClick}>{t(ORDERS_KEYS.RETRY_OPENING, 'Retry Opening')}</Button>
      ) : hasDesignatedRobot ? (
        <>
          <ErrorMessage>
            {t(ORDERS_KEYS.CONTACT_COCO_SUPPORT_AT, 'Please contact Coco Support at')}{' '}
            <a href={`tel:${SUPPORT_PHONE_NUMBER}`}>{SUPPORT_PHONE_NUMBER}</a>{' '}
            {t(ORDERS_KEYS.TO_REQUEST_PROVIDER_CHANGE, 'to request a change of delivery providers for this order.')}
          </ErrorMessage>
          <Button onClick={() => router.push('/')}>{t(ORDERS_KEYS.RETURN_TO_SCAN, 'Return to Scan')}</Button>
        </>
      ) : (
        <LoadableRobotsList onClickRobot={onClickRobot} />
      )}
    </ErrorsContainer>
  );
};
