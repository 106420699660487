import { useMutation, UseMutationOptions } from 'react-query';

import { useAppContext } from 'src/context';
import { usePersistStore } from 'src/stores';
import { openRobotLid } from '../services';
import useAmplitude from './useAmplitude';

interface Params {
  deliveryId: string;
  config: UseMutationOptions<string, Error, string, unknown>;
}

const useOpenRobotLid = ({ deliveryId, config }: Params) => {
  const { deliveriesApi } = useAppContext();
  const { addRobotAttemptedToOpen } = usePersistStore();
  const { logEvent } = useAmplitude();

  return useMutation({
    mutationFn: (robotSerial: string) =>
      openRobotLid({ deliveriesApi, logEvent, robotSerial, deliveryId, addRobotAttemptedToOpen }),
    ...config,
  });
};

export default useOpenRobotLid;
