import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonContainer, Header } from 'src/components';
import { ORDER_PROVIDER_MAP } from 'src/constants';
import { useAppContext } from 'src/context';
import { LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { styled } from 'src/stitches.config';
import { formatPrice } from 'src/utils';

const Container = styled('div', {
  display: 'flex',
  height: '90%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '800px',
});

const CustomerInfo = styled('div', {
  '& *': {
    marginTop: '$2',
  },
});

const OrderTable = styled('div', {
  width: '350px',
  marginBottom: '30px',
  '@media (max-width: 400px)': {
    width: '100%',
  },
});

const OrderRow = styled('div', {
  display: 'flex',
  minWidth: '300px',
  justifyContent: 'space-between',
  marginBottom: '20px',
});

const OrderLeft = styled('div', {
  fontWeight: '600',
  fontSize: '16px',
  textAlign: 'left',
});

const OrderRight = styled(OrderLeft, {
  textAlign: 'right',
  fontWeight: '400',
});

type Props = {
  robotId: string;
  handleSendRobotClick: () => void;
  handleBackBtnClick: () => void;
};

const OrderDetails: React.FC<Props> = ({ robotId, handleSendRobotClick, handleBackBtnClick }) => {
  const { t } = useTranslation(LOC_NS.ORDERS);
  const { selectedDelivery } = useAppContext();

  let trimmedCustomerName = 'Customer';
  if (selectedDelivery?.customerName && selectedDelivery.customerName.length > 20) {
    trimmedCustomerName = `${selectedDelivery.customerName.slice(0, 20)}...`;
  } else if (selectedDelivery?.customerName) {
    trimmedCustomerName = selectedDelivery.customerName;
  }

  return (
    <Container>
      <CustomerInfo>
        <Header>
          {t(ORDERS_KEYS.READ_TO_LOAD, 'Ready to load')} {robotId || 'Coco'}
        </Header>

        {selectedDelivery && (
          <OrderTable>
            <OrderRow>
              <OrderLeft>{trimmedCustomerName}</OrderLeft>
              <OrderRight>{ORDER_PROVIDER_MAP[selectedDelivery.orderSource]}</OrderRight>
            </OrderRow>
            {selectedDelivery.cartTotal !== 0 && (
              <OrderRow>
                <OrderLeft>{t(ORDERS_KEYS.TOTAL, 'Total')}</OrderLeft>
                <OrderRight>{formatPrice(selectedDelivery.cartTotal)}</OrderRight>
              </OrderRow>
            )}
          </OrderTable>
        )}
      </CustomerInfo>
      <ButtonContainer>
        <Button onClick={handleSendRobotClick}>{t(ORDERS_KEYS.SEND_COCO, 'Send Coco')}</Button>
        <Button className="grey" onClick={handleBackBtnClick}>
          {t(ORDERS_KEYS.BACK_TO_ALL_ORDERS, 'Back to all orders')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default OrderDetails;
