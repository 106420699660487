import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

interface PersistState {
  robotsAttemptedToOpen: string[];
  addRobotAttemptedToOpen: (robotSerial: string) => void;

  openLidRetryMode: boolean;
  setOpenLidRetryMode: (retryMode: boolean) => void;

  openLidRetryCount: number;
  increaseOpenLidRetryCount: () => void;
  clearOpenLidRetryCount: () => void;

  resetPersistStore: () => void;
}

const initialState = {
  robotsAttemptedToOpen: [],
  openLidRetryMode: false,
  openLidRetryCount: 0,
};

export const usePersistStore = create<PersistState>(
  persist(
    (set) => ({
      ...initialState,
      addRobotAttemptedToOpen: (robotSerial: string) => {
        set((state) => ({
          robotsAttemptedToOpen: [robotSerial, ...state.robotsAttemptedToOpen],
        }));
      },
      setOpenLidRetryMode: (retryMode: boolean) => {
        set({ openLidRetryMode: retryMode });
      },
      increaseOpenLidRetryCount: () => {
        set((state) => ({
          openLidRetryCount: state.openLidRetryCount + 1,
        }));
      },
      clearOpenLidRetryCount: () => {
        set({ openLidRetryCount: 0 });
      },
      resetPersistStore: () => {
        set(initialState);
      },
    }),
    {
      name: 'zustand-qr-persist-store',
    }
  ) as StateCreator<PersistState>
);
