import { useQuery } from 'react-query';

import { operationsQueryKeys } from 'src/@types';
import { useAppContext } from 'src/context';
import { getLoadableRobots } from 'src/services';
import useAmplitude from './useAmplitude';

export const useLoadableRobots = () => {
  const { logEvent } = useAmplitude();
  const { operationsApi, deliveriesApi, partnerIds } = useAppContext();

  return useQuery({
    queryKey: operationsQueryKeys.loadableRobots(partnerIds),
    queryFn: () => getLoadableRobots({ operationsApi, deliveriesApi, logEvent, partnerIds }),
    refetchInterval: 10 * 1000,
  });
};
