import { datadogLogs } from '@datadog/browser-logs';
import { AxiosError, AxiosInstance } from 'axios';

import { Delivery } from '@coco/types/deliveries';

import { AmplitudeEvent, EVENTS, Source } from '../@types';
import { OPERATIONS_API_ENDPOINT } from '../constants';
import { getErrorTypeFromErrorMessage } from '../utils';

interface OpenRobotLidParams {
  deliveriesApi: AxiosInstance;
  logEvent: (event: AmplitudeEvent) => void;
  robotSerial: string;
  deliveryId: string;
  addRobotAttemptedToOpen: (robotSerial: string) => void;
}

export const openRobotLid = async ({
  deliveriesApi,
  logEvent,
  robotSerial,
  deliveryId,
  addRobotAttemptedToOpen,
}: OpenRobotLidParams): Promise<string> => {
  const startTs = Date.now();
  try {
    addRobotAttemptedToOpen(robotSerial);
    const { data: openedRobotSerial } = await deliveriesApi.post<string>(
      `/api/v3/delivery/${deliveryId}/open-lid-for-load`,
      {
        preferredSerial: robotSerial,
      }
    );
    logEvent({
      eventName: EVENTS.OPEN_LID_PROFILING,
      params: {
        hook: 'OPEN_LID_PROFILING',
        errorMessage: '',
        timingMs: Date.now() - startTs,
      },
    });
    if (robotSerial !== openedRobotSerial) {
      datadogLogs.logger.log('Opened different robot than requested in Mx QR', {
        requestedRobotSerial: robotSerial,
        openedRobotSerial,
      });
    }
    datadogLogs.logger.log(`Mx QR requested to open lid for robot ${robotSerial}, opened ${openedRobotSerial}`);
    // for debugging purposes
    console.log(`Request to open lid for robot ${robotSerial}, opened ${openedRobotSerial}`);
    return openedRobotSerial;
  } catch (err) {
    const axiosError = err as AxiosError<{ message: string; serial: string }>;
    const errorMessage = axiosError?.response?.data?.message ?? axiosError?.message ?? 'unknown error';
    const serialAttemptedToOpen = axiosError?.response?.data?.serial;
    logEvent({
      eventName: EVENTS.OPEN_LID_FAILURE,
      params: {
        hook: 'OPEN_LID_PROFILING',
        errorMessage,
        errorType: getErrorTypeFromErrorMessage(errorMessage),
        timingMs: Date.now() - startTs,
      },
    });
    throw new Error(errorMessage, { cause: { serialAttemptedToOpen } });
  }
};

interface GetLoadableRobotsParams {
  operationsApi: AxiosInstance;
  deliveriesApi: AxiosInstance;
  logEvent: (event: AmplitudeEvent) => void;

  partnerIds: string[];
}

export const getLoadableRobots = async ({
  operationsApi,
  deliveriesApi,
  logEvent,
  partnerIds,
}: GetLoadableRobotsParams): Promise<string[]> => {
  const { data } = await operationsApi.get<string[]>(`${OPERATIONS_API_ENDPOINT}/loadable-robots`, {
    params: { partnerIds },
  });

  return (
    await Promise.all(
      data.map(async (serial) => {
        try {
          const { data: activeDeliveries } = await deliveriesApi.get<Delivery[]>(
            `/api/v3/delivery/active/robot/${serial}`
          );
          activeDeliveries?.forEach((delivery) => {
            if (delivery.source === Source.uber_eats_marketplace) {
              return null;
            }
          });
          return serial;
        } catch (err) {
          const errorMessage =
            (err as AxiosError<{ message: string }>)?.response?.data?.message ??
            (err as AxiosError<{ message: string }>)?.message ??
            'unknown error';
          logEvent({
            eventName: EVENTS.GET_LOADABLE_ROBOTS_ERROR,
            params: {
              hook: 'LOADABLE_ROBOTS',
              errorMessage,
            },
          });
          console.log(errorMessage);
          return null;
        }
      })
    )
  ).filter((x) => !!x) as string[];
};
