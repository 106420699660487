import { styled } from '@stitches/react';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-spinners/BarLoader';

import { useLoadableRobots } from 'src/hooks';
import { LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { usePersistStore } from 'src/stores';
import { Button } from '../Button';

interface Props {
  onClickRobot: (robotSerial: string) => void;
}

const Container = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.7rem',
});

const LoaderContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const NoOrdersContainer = styled('div', {
  display: 'flex',
  gap: '1rem',
  flexDirection: 'column',
  padding: '10px 20px',
  alignItems: 'center',
});

const HeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
});

const StyledHeader = styled('h4', {
  textAlign: 'center',
  marginTop: '1rem',
  marginBottom: '0.7rem',
  lineHeight: '1.5',
  flex: 1,
});

export const LoadableRobotsList = ({ onClickRobot }: Props) => {
  const { t } = useTranslation(LOC_NS.ORDERS);
  const router = useRouter();
  const { data: loadableRobotSerials, isLoading } = useLoadableRobots();
  const { robotsAttemptedToOpen } = usePersistStore();
  const robots = useMemo(() => {
    return loadableRobotSerials?.filter((robotSerial) => !robotsAttemptedToOpen.includes(robotSerial));
  }, [loadableRobotSerials, robotsAttemptedToOpen]);

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (!robots || robots?.length === 0) {
    return (
      <NoOrdersContainer>
        <StyledHeader>
          {t(ORDERS_KEYS.CHECK_TABLET_FOR_ETA, "Please check your tablet for the next Coco's ETA")}
        </StyledHeader>
        <Button onClick={() => router.push('/')}>{t(ORDERS_KEYS.RETURN_TO_SCAN, 'Return to Scan')}</Button>
      </NoOrdersContainer>
    );
  }

  return (
    <Container>
      <HeaderContainer>
        <StyledHeader>{t(ORDERS_KEYS.SELECT_ANOTHER_COCO, 'Please select another Coco to open')}</StyledHeader>
      </HeaderContainer>

      {robots.map((robotSerial) => {
        return (
          <Button key={robotSerial} onClick={() => onClickRobot(robotSerial)}>
            {robotSerial}
          </Button>
        );
      })}
    </Container>
  );
};
