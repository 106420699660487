import { ChevronRightIcon } from '@radix-ui/react-icons';
import { differenceInMinutes } from 'date-fns';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UrlObject } from 'url';

import { AppContext } from 'src/context';
import { LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { styled } from 'src/stitches.config';
import { usePersistStore } from 'src/stores';

const BreadcrumbsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '0px 20px',
  height: 'fit-content',
});

const BreadcrumbElement = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  '@media (max-width: 600px)': {
    fontSize: '10px',
  },
});

interface Breadcrumb {
  text: string;
  link: string | UrlObject;
}

export const Breadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const { t } = useTranslation(LOC_NS.ORDERS);
  const router = useRouter();
  const { robotId, setRobotId, canRedirect, setCanRedirect, canReload, setCanReload } = useContext(AppContext);
  const { robotsAttemptedToOpen } = usePersistStore();
  const pathname = usePathname();

  useEffect(() => {
    console.log('robotsAttemptedToOpen', robotsAttemptedToOpen);
  }, [robotsAttemptedToOpen]);

  const SCANNER_CRUMB = useMemo(
    () => ({
      text: t(ORDERS_KEYS.SCAN_A_COCO, 'Scan a Coco'),
      link: '/',
    }),
    [t]
  );
  const ORDERS_CRUMB = useMemo(
    () => ({
      text: t(ORDERS_KEYS.VIEW_ALL_ORDERS, 'View all orders'),
      link: {
        pathname: '/orders',
        query: { cocoId: robotId },
      },
    }),
    [t, robotId]
  );
  const SELECTED_ORDER_CRUMB = useMemo(() => {
    const orderMatch = !!pathname ? pathname.match(/^\/orders\/([^\/]+)$/) : null;
    if (orderMatch === null) {
      return undefined;
    }
    const orderId = orderMatch[1];
    return { text: t(ORDERS_KEYS.REVIEW_THIS_ORDER, 'Review this order'), link: `/orders/${orderId}` };
  }, [t, pathname]);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    // get directly from router to prevent race conditions
    const pathname = router.pathname;
    const query = router.query;
    const cocoIdFromUrl = query.cocoId as string;
    const scannedTs = query.ts as string;

    let isRecentScan = false;

    if (scannedTs && !Number.isNaN(parseInt(scannedTs, 10))) {
      const diffMinutes = differenceInMinutes(new Date(), new Date(parseInt(scannedTs, 10)));

      if (Math.abs(diffMinutes) < 10) {
        isRecentScan = true;
      }
    }

    console.log({
      scannedTs,
      currentTs: Date.now(),
      diff: differenceInMinutes(new Date(), new Date(parseInt(scannedTs as string, 10))),
      canRedirect,
      isRecentScan,
      pathname,
      query,
      cocoIdFromUrl,
      robotId,
    });

    if (pathname === '/orders' && !isRecentScan && canRedirect) {
      /* prevent scanning of inactive robots when user clicks on deeplink from browser history or autofill */
      console.log('redirecting to home', {
        scannedTs,
        currentTs: Date.now(),
      });
      setCanRedirect(false);
      router.push('/');
    } else if (pathname !== '/' && !robotId && cocoIdFromUrl) {
      console.log('no robotId, setting it from url');
      /* If page is refreshed (either intentionally or unintentionally), fetch robotId from url */
      setRobotId(cocoIdFromUrl);
    } else if (pathname !== '/' && robotId && !cocoIdFromUrl) {
      console.log('no cocoId search param, adding it to the url');
      /* If cocoId search param doesn't exist where robotId does, add it to the url */
      router.push({
        pathname,
        query: { cocoId: robotId },
      });
    } else if (pathname !== '/' && !robotId && !cocoIdFromUrl) {
      console.log('no robot params, redirecting to home');
      /* If url doesn't contain robot params, redirect to home page scanner */
      router.push('/');
    } else if (isRecentScan && canReload) {
      console.log('(external scan fix) pushing to route: ', router.asPath);
      router.push(router.asPath);
      setCanReload(false);
      setCanRedirect(false);
    }
  }, [canRedirect, canReload, robotId, router, setCanRedirect, setCanReload, setRobotId]);

  useEffect(() => {
    if (pathname === '/orders') {
      setBreadcrumbs([SCANNER_CRUMB, ORDERS_CRUMB]);
    } else if (!!SELECTED_ORDER_CRUMB) {
      setBreadcrumbs([SCANNER_CRUMB, ORDERS_CRUMB, SELECTED_ORDER_CRUMB]);
    } else {
      setBreadcrumbs([]);
    }
  }, [pathname, SCANNER_CRUMB, ORDERS_CRUMB, SELECTED_ORDER_CRUMB]);

  return (
    <BreadcrumbsContainer>
      {breadcrumbs.map(({ text, link }, idx) => {
        return (
          <BreadcrumbElement
            key={link.toString()}
            onClick={async () => {
              await router.push(link);
            }}
            css={{ fontWeight: idx === breadcrumbs.length - 1 ? '600' : '400' }}
          >
            {text}
            {idx < breadcrumbs.length - 1 && <ChevronRightIcon />}
          </BreadcrumbElement>
        );
      })}
    </BreadcrumbsContainer>
  );
};
